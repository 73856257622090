<template>
  <router-link
    :class="[
      'v-workspace-status',
      `v-workspace-status__${customType}`,
    ]"
    :to="{
      name: 'ws-settings-workspace-billing',
      params: {
        wsId: workspaceId,
      },
    }"
  >
    {{ customType }}
  </router-link>
</template>

<script>
export default {
  name: 'VWorkspaceStatus',

  props: {
    customType: {
      type: String,
      required: true,
      validator(val) {
        return [
          'free',
          'pro',
        ].indexOf(val) !== -1;
      },
    },
    workspaceId: {
      type: [Number, String],
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.v-workspace-status {
  padding: 0 5px;
  height: 13px;
  width: max-content;
  font-size: 0.75rem;
  font-weight: 300;
  color: #FFF !important;
  border-radius: 2px;
  text-decoration: none;
  cursor: pointer;
}

.v-workspace-status__free {
  background-color: $darkGrey;
  box-shadow: 0 3px 6px 0 rgba(163, 163, 163, .42);
}

.v-workspace-status__pro {
  background: linear-gradient(to top, rgb(125, 158, 241) 0%, rgb(117, 183, 248) 100%);
  box-shadow: 0 3px 6px 0 rgba(125, 157, 241, .42);
}
</style>
