var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{class:[
    'v-workspace-status',
    ("v-workspace-status__" + _vm.customType) ],attrs:{"to":{
    name: 'ws-settings-workspace-billing',
    params: {
      wsId: _vm.workspaceId,
    },
  }}},[_vm._v(" "+_vm._s(_vm.customType)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }